<template>
    <AModal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="50%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- toko LT -->
            <AFormItem
                label="Pilih Toko LT"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="lt"
                has-feedback>
                <FilterLt
                    style="width:100%;"
                    :mode="null"
                    v-model:value="form.lt"/>
            </AFormItem>

            <!-- toko -->
            <AFormItem
                label="Pilih Toko"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="toko"
                has-feedback>
                <FilterToko
                    style="width:100%;"
                    v-model:value="form.toko"/>
            </AFormItem>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>
        
        <div v-if="state.errors.length" class="mt-2">
            <AAlert
                v-for="(item, index) in state.errors"
                class="mb-2"
                :type="item.success ? 'success' : 'error'"
                :key="index"
                :message="item.message"
                banner
                closable
                @close="state.errors = []" />
        </div>

        <div v-if="form.errors.any()">
            <AAlert
                v-for="(item, index) in form.errors.all()"
                type="error"
                class="mb-2"
                :key="index"
                :message="item.message"
                banner
                closable />
        </div>
    </AModal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import { Modal, message } from 'ant-design-vue'
import FilterToko from '@/components/filter/FilterToko'
import FilterLt from '@/components/filter/FilterLt'
import moment from 'moment'
import {
    ROLE_ADMIN_BK,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterToko,
        FilterLt,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            lt: null,
            toko: [],
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat - Master Data Jaringan LT',
            endpoint: {
                store: '/api/customer-lt/add-mapping',
            },
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                lt: [
                    {
                        required: true,
                        message: 'Toko LT tidak boleh kosong!',
                    },
                ],
                toko: [
                    {
                        required: true,
                        message: "Toko tidak boleh kosong!",
                    },
                ],
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const disabledStartDate = (current) => {
            return current && current > moment(form.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(form.begin_date) >= current
        }

        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => {});

            form.post(state.endpoint.store)
                .then(({ data }) => {
                    if (data === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }

                    state.errors = data

                    // handleModalCancel()
                    emit('success', data)
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        if (Array.isArray(message)) {
                            state.errors = message
                        } else {
                            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                        }
                    }
                })
        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} - Master Data Jaringan LT`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            disabledStartDate,
            disabledEndDate,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
